/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-duplicates */
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import ModalImage from "react-modal-image";
import ptBR from "date-fns/locale/pt-BR";
import format from "date-fns/format";
import { useNavigate, useParams } from "react-router-dom";
import { parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { enqueueSnackbar } from "notistack";

import { PageTitle } from "../../../components/PageTitle";
import { AuthContext } from "../../../contexts/AuthContext";
import { Input } from "../../../components/Input";
import { api } from "../../../services/api";
import { ModalAddResponsible } from "../components/ModalAddResponsible";
import { ModalAddSignature } from "../components/ModalAddSignature";

interface Services {
  service_id: string;
  name: string;
}

interface Equipments {
  equipment_id: string;
  grade: string;
  equipment: Equipment;
}

interface Equipment {
  name: string;
}

interface Sector {
  name: string;
}

interface Sectors {
  sector_id: string;
  sector: Sector;
  name: string;
  equipments: Equipments[];
  observation: string;
  images: any;
  show: boolean;
}

interface Costumer {
  id: string;
  name: string;
}

interface TechVisitReport {
  costumer: Costumer;
  begin: string;
  end: string;
  observation: string;
  pending: boolean;
  sectors: Sectors[];
  services: Services[];
}

interface Responsible {
  id: string;
  name: string;
}

interface Technician {
  id: string;
  name: string;
}

interface FormResponsibles {
  responsible_id: string;
  technician_id: string;
}

const schema = Yup.object().shape({
  responsible_id: Yup.string().required("Selecione um responsável"),
  technician_id: Yup.string().required("Selecione um técnico"),
});

export function TechVisitReportConfirm() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [report, setReport] = useState<TechVisitReport>();
  const [responsibles, setResponsibles] = useState<Responsible[]>([]);
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [modalAddResponsible, setModalAddResponsible] = useState(false);
  const [modalAddSignature, setModalAddSignature] = useState(false);
  const [signatureType, setSignatureType] = useState("");
  const [responsibleSignature, setResponsibleSignature] = useState(false);
  const [technicianSignature, setTechnicianSignature] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/techVisitReport/${id}`).then(response => {
      response.data.sectors.map(sector => {
        return Object.assign(sector, { show: false });
      });

      return setReport(response.data);
    });

    api
      .get("/users", {
        params: { status: "A" },
      })
      .then(response => {
        return setTechnicians(response.data);
      });
  }, []);

  useEffect(() => {
    if (report !== undefined) {
      api
        .get("/responsibles", {
          params: { costumer_id: report.costumer.id, status: "A" },
        })
        .then(response => {
          return setResponsibles(response.data);
        });
    }
  }, [report, modalAddResponsible]);

  async function handleSubmit(data: FormResponsibles) {
    api
      .patch(`/techVisitReport/responsibles/${id}`, data)
      .then(() => {
        enqueueSnackbar("Relatório cadastrado com sucesso!", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch(({ response }) => {
        enqueueSnackbar(response.data.message, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });

    return navigate(`/tech-visit-reports`);
  }

  const initialValues = {
    responsible_id: "",
    technician_id: "",
    sectors: !report ? [] : report.sectors,
  };

  function gradeWord(grade) {
    let word = "";

    switch (grade) {
      case "O":
        word = "Ótimo";
        break;
      case "B":
        word = "Bom";
        break;
      case "R":
        word = "Ruim";
        break;
      default:
        word = "";
        break;
    }

    return word;
  }

  return (
    <div className="container mx-auto">
      <PageTitle
        title="Confirmação do Relatório"
        backButton
        backPath={`/tech-visit-reports/new/${id}`}
      />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="flex flex-col flex-wrap">
            {report && (
              <>
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Data e hora do início da visita
                </p>
                <Input
                  type="text"
                  value={format(parseISO(report.begin), "dd/MM/yyyy - HH:mm", {
                    locale: ptBR,
                  })}
                  disabled
                />
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Data e hora do término da visita
                </p>
                <Input
                  type="text"
                  value={format(parseISO(report.end), "dd/MM/yyyy - HH:mm", {
                    locale: ptBR,
                  })}
                  disabled
                />
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Cliente
                </p>
                <Input type="text" value={report.costumer.name} disabled />
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Serviços prestados
                </p>
                <div className="bg-[#0085BD] w-full rounded-lg p-4 mt-1">
                  <ul className="text-small text-white">
                    {report.services.map(({ name: equipment_name }) => (
                      <li>{equipment_name}</li>
                    ))}
                  </ul>
                </div>
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Setores
                </p>
                <FieldArray
                  name="sectors"
                  render={arraySectors => (
                    <>
                      {values.sectors && values.sectors.length > 0 ? (
                        values.sectors.map(({ show }: Sectors, indexSector) => (
                          <div className="h-fit mb-2">
                            <div className="grid grid-cols-1">
                              <div className="flex items-center justify-center">
                                {show === true ? (
                                  <button
                                    type="button"
                                    className="relative top-[4px] p-1 bg-white rounded-full"
                                    onClick={() =>
                                      setFieldValue(
                                        `sectors[${indexSector}].show`,
                                        false,
                                      )
                                    }
                                  >
                                    <IoIosArrowUp size={20} />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="relative top-[4px] p-1 bg-white rounded-full"
                                    onClick={() =>
                                      setFieldValue(
                                        `sectors[${indexSector}].show`,
                                        true,
                                      )
                                    }
                                  >
                                    <IoIosArrowDown size={20} />
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="bg-[#0085BD] w-full rounded-lg p-4 mt-[-9px]">
                              <div className="flex gap-2">
                                <Input
                                  type="text"
                                  name={values.sectors[indexSector].sector.name}
                                  value={
                                    values.sectors[indexSector].sector.name
                                  }
                                  disabled
                                />
                              </div>
                              {values.sectors[indexSector].show === true && (
                                <>
                                  <div className="mt-2 grid grid-cols-2 text-white">
                                    <div>
                                      <p className="font-medium">
                                        Equipamentos
                                      </p>
                                    </div>
                                    <div className="flex justify-center">
                                      <p className="font-medium">Nota</p>
                                    </div>
                                    {values.sectors[indexSector].equipments.map(
                                      ({ equipment, grade }) => (
                                        <>
                                          {grade !== "" && (
                                            <>
                                              <div>{equipment.name}</div>
                                              <div className="flex justify-center">
                                                {gradeWord(grade)}
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ),
                                    )}
                                  </div>
                                  {values.sectors[indexSector].observation !==
                                    "" && (
                                    <>
                                      <p className="text-small font-medium text-white mt-2">
                                        Observações
                                      </p>
                                      <p className="p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin">
                                        {
                                          values.sectors[indexSector]
                                            .observation
                                        }
                                      </p>
                                    </>
                                  )}
                                  <div>
                                    <div className="flex flex-row flex-wrap">
                                      {values.sectors[indexSector].images.map(
                                        file => (
                                          <ModalImage
                                            className="w-16 h-12 ml-1 mt-1 overflow-hidden bg-white rounded-lg border-dashed border-2 border-[#0085BD] flex items-center justify-center"
                                            small={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSectorImages/${file.file_name}`}
                                            large={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSectorImages/${file.file_name}`}
                                          />
                                        ),
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div />
                      )}
                      <div />
                    </>
                  )}
                />
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Ficou algum pendência
                </p>
                <Input
                  type="text"
                  name="costumer"
                  value={report.pending === true ? "Sim" : "Não"}
                  disabled
                />
                <p className="text-sm text-[#0085BD] font-medium mt-2">Notas</p>
                <p className="p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin">
                  {report.observation}
                </p>
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Responsável Técnico
                </p>
                <Field
                  as="select"
                  name="technician_id"
                  className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                >
                  <option value="" defaultChecked disabled>
                    Selecione o responsável
                  </option>
                  {technicians &&
                    technicians.map(({ id: technician_id, name }) => (
                      <option value={technician_id}>{name}</option>
                    ))}
                </Field>
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Assinatura Técnico
                </p>
                <button
                  type="button"
                  className={`flex font-medium items-center justify-center min-h-[56px] text-sm text-white rounded-lg ${
                    technicianSignature ? "bg-[#92C163]" : "bg-[#0085BD]"
                  } transition-colors`}
                  onClick={() => {
                    setModalAddSignature(true);
                    setSignatureType("technician_signature");
                  }}
                  disabled={technicianSignature}
                >
                  {technicianSignature
                    ? "Assinatura do Técnico Realizada"
                    : "Adicionar Assinatura Técnico"}
                </button>
                {modalAddSignature && (
                  <div
                    className={!modalAddSignature ? "hidden" : "relative z-10"}
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <ModalAddSignature
                      id={id}
                      type={signatureType}
                      setModalAddSignature={setModalAddSignature}
                      setTechnicianSignature={setTechnicianSignature}
                      setResponsibleSignature={setResponsibleSignature}
                    />
                  </div>
                )}
                {/* <SignaturePad
                    ref={signCanvasTech}
                    clearOnResize
                    canvasProps={{
                      className: "mt-1 bg-white rounded-lg w-full h-40",
                    }}
                  />
                  <div className="flex gap-1">
                    <FormButton
                      type="button"
                      title="Apagar"
                      onClick={() => clearSignatureTech()}
                    />
                    <button
                      type="button"
                      onClick={() => saveTechnicianSignature()}
                      className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#92C163]"
                    >
                      Salvar Assinatura
                    </button>
                  </div> */}
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Responsável Cliente
                </p>
                <div className="flex gap-2">
                  <Field
                    as="select"
                    name="responsible_id"
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                  >
                    <option value="" defaultChecked disabled>
                      Selecione o responsável
                    </option>
                    {responsibles &&
                      responsibles.map(
                        ({ id: responsible_id, name: responsible_name }) => (
                          <option value={responsible_id}>
                            {responsible_name}
                          </option>
                        ),
                      )}
                  </Field>
                  <button
                    type="button"
                    className="flex items-center justify-center min-h-[56px] w-10 text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                    onClick={() => setModalAddResponsible(true)}
                  >
                    <AiOutlinePlus size={16} />
                  </button>
                  {modalAddResponsible && (
                    <div
                      className={
                        !modalAddResponsible ? "hidden" : "relative z-10"
                      }
                      aria-labelledby="modal-title"
                      role="dialog"
                      aria-modal="true"
                    >
                      <ModalAddResponsible
                        setModalAddResponsible={setModalAddResponsible}
                        costumer_id={report.costumer.id}
                      />
                    </div>
                  )}
                </div>
                <p className="text-sm text-[#0085BD] font-medium mt-2">
                  Assinatura Cliente
                </p>
                <button
                  type="button"
                  className={`flex font-medium items-center justify-center min-h-[56px] text-sm text-white rounded-lg ${
                    responsibleSignature ? "bg-[#92C163]" : "bg-[#0085BD]"
                  } transition-colors`}
                  onClick={() => {
                    setModalAddSignature(true);
                    setSignatureType("responsible_signature");
                  }}
                  disabled={responsibleSignature}
                >
                  {responsibleSignature
                    ? "Assinatura do Cliente Realizada"
                    : "Adicionar Assinatura Cliente"}
                </button>
                {modalAddSignature && (
                  <div
                    className={!modalAddSignature ? "hidden" : "relative z-10"}
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <ModalAddSignature
                      id={id}
                      type={signatureType}
                      setModalAddSignature={setModalAddSignature}
                      setTechnicianSignature={setTechnicianSignature}
                      setResponsibleSignature={setResponsibleSignature}
                    />
                  </div>
                )}
                {/* <SignaturePad
                    ref={signCanvasCostumer}
                    clearOnResize
                    canvasProps={{
                      className: "mt-1 bg-white rounded-lg w-full h-40",
                    }}
                  />
                  <div className="flex gap-1">
                    <FormButton
                      type="button"
                      title="Apagar"
                      onClick={() => clearSignatureCostumer()}
                    />
                    <button
                      type="button"
                      onClick={() => saveResponsibleSignature()}
                      className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#92C163]"
                    >
                      Salvar Assinatura
                    </button>
                  </div> */}
                <div className="flex gap-1">
                  <Link
                    to={`/tech-visit-reports/new/${id}`}
                    className="w-full flex items-center justify-center mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#0085BD]"
                  >
                    Voltar
                  </Link>
                  <button
                    type="submit"
                    className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#92C163]"
                  >
                    Salvar
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
