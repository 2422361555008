import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { ItemPaginationReport } from "../../../components/ItemPaginationReport";
// import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading";

interface TechVisitReport {
  id: string;
  costumer_id: string;
  user_id: string;
  code: string;
  begin: string;
  end: string;
  responsible_id: string;
  responsible_signature: string;
  technician_id: string;
  technician_signature: string;
  observation: string;
  pending: boolean;
  sent: boolean;
  costumer: Costumer;
  user: User;
  status: string;
  // technician: ;
  services: TechVisitReportService[];
  sectors: TechVisitReportSector[];
}

interface Costumer {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
}

interface TechVisitReportService {
  id: string;
  name: string;
}

interface TechVisitReportSector {
  id: string;
  sector_id: string;
  observation: string;
  sector: Sector;
  equipments: TechVisitReportSectorEquipment[];
  images: TechVisitReportSectorImage[];
}

interface TechVisitReportSectorEquipment {
  id: string;
  equipment_id: string;
  grade: string;
  equipment: Equipment;
}

interface TechVisitReportSectorImage {
  id: string;
  file_name: string;
}

interface Equipment {
  id: string;
  name: string;
}

interface Sector {
  id: string;
  costumer_id: string;
  name: string;
}

interface Service {
  id: string;
  name: string;
}

interface ReportStatus {
  total: number;
  pending: number;
  concluded: number;
}

export function TechVisitReportList() {
  // Ajuste no gerenciamento de estado e paginação infinita
  const [techVisitReports, setTechVisitReports] = useState<TechVisitReport[]>(
    [],
  );
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [costumer_id, setCostumerId] = useState("");
  const [pending, setPending] = useState("");
  const [sent, setSent] = useState("");
  const [statusFilter, setStatusFilter] = useState("A");
  const [reportStatus, setReportStatus] = useState<ReportStatus>({
    total: 0,
    pending: 0,
    concluded: 0,
  });
  const [pagination, setPagination] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [beginFilter, setBeginFilter] = useState(
    format(new Date(), "yyyy-MM-dd 00:00:00"),
  );
  const [endFilter, setEndFilter] = useState(
    format(new Date(), "yyyy-MM-dd 23:59:59"),
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  console.log(page);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();

  // Função para carregar a primeira página ou reiniciar os filtros
  function paginarTechVisitReports() {
    if (loading) return;

    setLoading(true);
    setPage(1); // Reinicia a página ao carregar novos filtros
    api
      .get("/techVisitReport", {
        params: {
          costumer_id,
          pending,
          sent,
          status: statusFilter,
          begin: beginFilter,
          end: endFilter,
          page: 1,
          limit: 10,
        },
      })
      .then(response => {
        const result = response.data.data;
        setTechVisitReports(result);
        setHasMore(result.length > 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  // Função para carregar mais páginas ao fazer scroll
  function paginarTechVisitReportsMore() {
    if (loading || !hasMore) return;

    setLoading(true);
    api
      .get("/techVisitReport", {
        params: {
          costumer_id,
          pending,
          sent,
          status: statusFilter,
          begin: beginFilter,
          end: endFilter,
          page,
          limit: 10,
        },
      })
      .then(response => {
        const result = response.data.data;
        setTechVisitReports(prev => [...prev, ...result]);
        setHasMore(result.length > 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  // Carregar dados ao alterar filtros
  useEffect(() => {
    paginarTechVisitReports();
  }, [costumer_id, pending, sent, statusFilter, beginFilter, endFilter]);

  // Carregar mais dados ao alterar a página
  useEffect(() => {
    if (page > 1) {
      paginarTechVisitReportsMore();
    }
  }, [page]);

  // Atualizar contagem de relatórios
  useEffect(() => {
    const pendingCount = techVisitReports.filter(
      element => element.pending === false,
    ).length;
    const concludedCount = techVisitReports.filter(
      element => element.pending === true,
    ).length;

    setReportStatus({
      total: techVisitReports.length,
      pending: pendingCount,
      concluded: concludedCount,
    });
  }, [techVisitReports]);

  // Função de scroll para carregar mais dados
  const handleScroll = () => {
    if (loading || !hasMore) return;

    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomPosition = document.documentElement.scrollHeight;

    if (scrollPosition >= bottomPosition - 100) {
      setPage(page + 1);
    }
  };

  // Adicionar e remover evento de scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  // Carregar lista de clientes ao montar o componente
  useEffect(() => {
    api
      .get("/costumers")
      .then(response => {
        setCostumers(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-[#0085BD] rounded-lg p-4 flex items-center">
            <div className="flex-1">
              <span className="text-3xl font-bold text-white">
                {reportStatus.total}
              </span>
              <p className="text-white mt-1">Relatórios</p>
            </div>
          </div>
          <div className="bg-[#92C163] rounded-lg p-4 flex items-center">
            <div className="flex-1">
              <span className="text-3xl font-bold text-white">
                {reportStatus.concluded}
              </span>
              <p className="text-white mt-1">Concluídos</p>
            </div>
          </div>
          <div className="bg-danger-500 rounded-lg p-4 flex items-center">
            <div className="flex-1">
              <span className="text-3xl font-bold text-white">
                {reportStatus.pending}
              </span>
              <p className="text-white mt-1">Pendentes</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 bg-white p-4 rounded-md">
          <div className="space-y-2">
            <span className="text-small font-medium text-[#0085BD]">
              Clientes
            </span>
            <select
              name="costumer_id"
              onChange={e => setCostumerId(e.target.value)}
              className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
            >
              <option value="">Todos</option>
              {costumers?.map(({ id: costumerId, name }) => (
                <option key={costumerId} value={costumerId}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-2">
            <span className="text-small font-medium text-[#0085BD]">
              Status conclusão
            </span>
            <select
              onChange={e => setPending(e.target.value)}
              className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
            >
              <option value="">Todos</option>
              <option value="true">Pendente</option>
              <option value="false">Concluído</option>
            </select>
          </div>

          <div className="space-y-2">
            <span className="text-small font-medium text-[#0085BD]">
              Status envio
            </span>
            <select
              onChange={e => setSent(e.target.value)}
              className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
            >
              <option value="">Todos</option>
              <option value="true">Enviado</option>
              <option value="false">Não enviado</option>
            </select>
          </div>

          <div className="space-y-2">
            <span className="text-small font-medium text-[#0085BD]">
              Status relatório
            </span>
            <select
              onChange={e => setStatusFilter(e.target.value)}
              defaultValue="A"
              className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
            >
              <option value="">Todos</option>
              <option value="A">Ativo</option>
              <option value="I">Inativo</option>
            </select>
          </div>

          <div className="space-y-2">
            <span className="text-small font-medium text-[#0085BD]">Data</span>
            <div>
              <DatePicker
                className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                selectsRange
                startDate={startDate}
                endDate={endDate}
                isClearable
                onChange={update => {
                  setDateRange(update);
                  const begin = moment(update[0]).format("YYYY-MM-DDT00:00:00");
                  const end = moment(update[1]).format("YYYY-MM-DDT23:59:59");

                  if (update[0] === null && update[1] === null) {
                    setBeginFilter(null);
                    setEndFilter(null);
                  }

                  if (begin <= end && update[1] !== null) {
                    setBeginFilter(begin);
                    setEndFilter(end);
                  }
                }}
                locale="ptBR"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>

        <div className="space-y-4">
          {techVisitReports.length > 0 ? (
            techVisitReports.map(
              ({
                id,
                costumer,
                begin,
                sent: sented,
                pending: pended,
                status,
                services,
                code,
              }) => (
                <ItemPaginationReport
                  key={id}
                  id={id}
                  title={costumer.name}
                  begin={begin}
                  pending={pended}
                  sent={sented}
                  status={status}
                  services={services}
                  costumer_id={costumer.id}
                  code={code}
                  setPagination={() => setPagination(!pagination)}
                />
              ),
            )
          ) : (
            <div className="bg-[#0085BD] rounded-lg p-5">
              <span className="text-sm font-medium text-white">
                Nenhum registro foi encontrado 👀
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
