import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { api } from "../../services/api";

import { ItemPagination } from "../../components/ItemPagination";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface Sector {
  id: string;
  name: string;
  status: string;
  costumer: Costumer;
}

interface Costumer {
  id: string;
  name: string;
}

export function Sectors() {
  const { user } = useContext(AuthContext);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [costumer_id, setCostumerId] = useState("");
  const [changeStatus, setChangeStatus] = useState<number>(0);

  useEffect(() => {
    api.get("/sectors").then(response => {
      return setSectors(response.data);
    });

    api.get("/costumers").then(response => {
      return setCostumers(response.data);
    });
  }, []);

  useEffect(() => {
    api
      .get("/sectors", {
        params: {
          costumer_id,
        },
      })
      .then(response => {
        return setSectors(response.data);
      });
  }, [costumer_id, changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`sectors/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="container mx-auto">
      <PageTitle title="Setores" backButton backPath="/tech-visit-reports" />
      <div className="flex items-start justify-between mb-6">
        <Link
          to="/sectors/new"
          className="bg-[#0085BD] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:bg-[#006a96] transition-colors"
        >
          <FiPlus size={24} />
        </Link>
      </div>
      <div className="space-y-2">
        <span className="text-small text-[#0085BD]">Cliente</span>
        <select
          name="costumer_id"
          onChange={e => setCostumerId(e.target.value)}
          className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
        >
          <option value="" defaultChecked>
            Todos
          </option>
          {costumers &&
            costumers.map(({ id: costumerId, name }: Costumer) => (
              <option key={costumerId} value={costumerId}>
                {name}
              </option>
            ))}
        </select>
      </div>
      {sectors &&
        sectors.map(({ id, name, costumer, status }: Sector) => (
          <ItemPagination
            key={id}
            title={name}
            description={costumer.name}
            id={id}
            status={status}
            hasDelete
            handleChangeStatus={() => handleChangeStatus(id, status)}
          />
        ))}
    </div>
  );
}
