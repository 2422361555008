import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";

import { useContext, useEffect, useState } from "react";

import { ItemPagination } from "../../components/ItemPagination";
import { api } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface User {
  id: string;
  name: string;
  occupation: string;
  status: string;
}

export function Users() {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [changeStatus, setChangeStatus] = useState<number>(0);

  useEffect(() => {
    api.get("/users").then(response => {
      return setUsers(response.data);
    });
  }, [changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`users/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="container mx-auto">
      <PageTitle title="Usuários" backButton backPath="/tech-visit-reports" />
      <div className="flex items-start justify-between mb-6">
        <Link
          to="/users/new"
          className="bg-[#0085BD] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:bg-[#006a96] transition-colors"
        >
          <FiPlus size={24} className="text-white" />
        </Link>
      </div>
      <div className="space-y-2">
        {users.length > 0 ? (
          users.map(({ id, name, occupation, status }: User) => (
            <ItemPagination
              key={id}
              title={name}
              id={id}
              status={status}
              description={occupation}
              hasDelete
              handleChangeStatus={() => handleChangeStatus(id, status)}
            />
          ))
        ) : (
          <div className="col-span-full">
            <div className="w-full p-5 rounded-lg bg-[#0085BD]">
              <span className="text-sm font-medium text-white">
                Nenhum usuário encontrado 👀
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
