/* eslint-disable import/no-extraneous-dependencies */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";

import { FormButton } from "../../../components/FormButton";
import { Input } from "../../../components/Input";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { PageTitle } from "../../../components/PageTitle";
import { InputMask } from "../../../components/InputMask";
import { maskPhone } from "../../../components/masks/Masks";

interface Responsible {
  name: string;
  email: string;
  phone: number;
  costumer?: Costumer;
}

interface Costumer {
  id: string;
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo responsável é obrigatório"),
  email: Yup.string()
    .email("E-mail inválido")
    .required("Campo e-mail é obrigatório"),
});

export function ResponsibleEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [responsible, setResponsible] = useState<Responsible>();
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [phoneMask, setPhoneMask] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      api.get(`responsibles/${id}`).then(response => {
        return setResponsible(response.data);
      });
    }

    api.get("/costumers").then(response => {
      return setCostumers(response.data);
    });
  }, [id]);

  function handleSubmit(data: Responsible) {
    if (id) {
      api
        .put(`/responsibles/${id}`, data)
        .then(() => {
          enqueueSnackbar("Responsável editado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    } else {
      api
        .post("/responsibles", data)
        .then(() => {
          enqueueSnackbar("Responsável cadastrado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    }
    return navigate("/responsibles");
  }

  const initialValues = {
    name: responsible ? responsible.name : "",
    email: responsible ? responsible.email : "",
    phone: responsible ? responsible.phone : null,
    costumer_id: responsible ? responsible.costumer : "",
  };

  return (
    <div className="container mx-auto">
      <PageTitle
        title={id ? "Editar Responsável" : "Novo Responsável"}
        backButton
        backPath="/responsibles"
      />
      <div className="w-full">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ errors, setFieldValue }) => (
              <Form className="space-y-4">
                <div className="space-y-2">
                  <span className="text-sm text-[#0085BD] font-medium">
                    Responsável
                  </span>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Responsável"
                    error={errors.name}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  <span className="text-sm text-[#0085BD] font-medium">
                    E-mail
                  </span>
                  <Input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    error={errors.email}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  <span className="text-sm text-[#0085BD] font-medium">
                    Telefone
                  </span>
                  <InputMask
                    value={phoneMask}
                    onChange={e => {
                      setFieldValue("phone", e.target.value);
                      setPhoneMask(maskPhone(e.target.value));
                    }}
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  {!id && (
                    <div className="mt-1">
                      <span className="text-sm text-[#0085BD] font-medium">
                        Cliente
                      </span>{" "}
                      <Field
                        as="select"
                        name="costumer_id"
                        className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                      >
                        <option value="" selected disabled>
                          Selecione um cliente
                        </option>
                        {costumers &&
                          costumers.map(
                            ({ id: costumer_id, name }: Costumer) => (
                              <option key={costumer_id} value={costumer_id}>
                                {name}
                              </option>
                            ),
                          )}
                      </Field>
                    </div>
                  )}
                </div>
                <FormButton
                  title={id ? "Editar Responsável" : "Cadastrar Responsável"}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
