/* eslint-disable import/no-extraneous-dependencies */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";

import { FormButton } from "../../../components/FormButton";
import { Input } from "../../../components/Input";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { PageTitle } from "../../../components/PageTitle";

interface Service {
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo serviço é obrigatório"),
});

export function ServiceEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [service, setService] = useState<Service>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      api.get(`services/${id}`).then(response => {
        return setService(response.data);
      });
    }
  }, [id]);

  function handleSubmit(data: Service) {
    if (id) {
      api
        .put(`/services/${id}`, data)
        .then(() => {
          enqueueSnackbar("Serviço editado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    } else {
      api
        .post("/services", data)
        .then(() => {
          enqueueSnackbar("Serviço cadastrado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    }
    return navigate("/services");
  }

  const initialValues = {
    name: service ? service.name : "",
  };

  return (
    <div className="container mx-auto">
      <PageTitle
        title={id ? "Editar Serviço" : "Novo Serviço"}
        backButton
        backPath="/services"
      />
      <div className="w-full">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ errors }) => (
              <Form className="space-y-4">
                <div className="space-y-2">
                  <span className="text-sm text-[#0085BD] font-medium">
                    Serviço
                  </span>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Serviço"
                    error={errors.name}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                </div>
                <FormButton
                  title={id ? "Editar Serviço" : "Cadastrar Serviço"}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
