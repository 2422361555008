/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SignaturePad from "react-signature-canvas";
import { FormButton } from "../../../components/FormButton";
import { Loading } from "../../../components/Loading";
import { api } from "../../../services/api";

interface ModalAddSignatureProps {
  id: string;
  type: string;
  setModalAddSignature(showModal: boolean): void;
  setTechnicianSignature(signature: boolean): void;
  setResponsibleSignature(signature: boolean): void;
}

export function ModalAddSignature({
  id,
  type,
  setModalAddSignature,
  setTechnicianSignature,
  setResponsibleSignature,
}: ModalAddSignatureProps) {
  const [loading, setLoading] = useState(false);
  const signCanvas = React.useRef() as React.MutableRefObject<any>;
  const closeButtonRef = useRef(null);
  const clearSignature = () => signCanvas.current.clear();

  function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  async function saveResponsibleSignature() {
    try {
      setLoading(true);
      const fileResponsible = DataURIToBlob(
        signCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
      );

      const signature = new FormData();

      signature.append("signature_file", fileResponsible, "image.jpg");

      await api.patch(`/techVisitReport/${type}/${id}`, signature);

      setModalAddSignature(false);

      if (type === "technician_signature") {
        setTechnicianSignature(true);
      } else if (type === "responsible_signature") {
        setResponsibleSignature(true);
      }

      return setLoading(false);
    } catch (e) {
      setLoading(false);
      return setModalAddSignature(false);
    }
  }

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, []);

  const title = type === "responsible_signature" ? "Cliente" : "Técnico";

  return (
    <>
      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white/10 backdrop-blur-sm p-4 rounded-lg flex items-center space-x-3">
            <div className="animate-spin h-6 w-6 border-3 border-t-transparent border-white rounded-full" />
            <span className="text-white font-medium">Carregando...</span>
          </div>
        </div>
      )}

      {/* Modal Backdrop */}
      <div
        className="fixed inset-0 bg-black/60 backdrop-blur-sm transition-opacity z-40"
        onClick={() => setModalAddSignature(false)}
        aria-hidden="true"
      />

      {/* Modal Container */}
      <div
        className="fixed inset-0 overflow-y-auto z-50"
        role="dialog"
        aria-modal="true"
        aria-labelledby="signature-modal-title"
      >
        <div className="flex min-h-full items-center justify-center p-4 sm:p-6">
          {/* Modal Content */}
          <div className="relative bg-gradient-to-b from-[#8ACFEA] to-[#77C5E5] rounded-xl p-5 shadow-2xl transform transition-all w-full max-w-md mx-auto border border-white/20">
            {/* Header */}
            <div className="flex justify-between items-center border-b border-white/30 pb-3 mb-4">
              <h1
                id="signature-modal-title"
                className="text-2xl font-bold text-white"
              >
                Assinatura {title}
              </h1>
              <button
                ref={closeButtonRef}
                type="button"
                onClick={() => setModalAddSignature(false)}
                className="text-white hover:text-gray-200 transition-colors p-1 rounded-full hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-white/50"
                aria-label="Fechar modal"
              >
                <AiOutlineClose size={24} />
              </button>
            </div>

            {/* Signature Pad Container */}
            <div className="bg-white/10 backdrop-blur-sm p-3 rounded-lg mb-4">
              <SignaturePad
                ref={signCanvas}
                clearOnResize
                canvasProps={{
                  className: "bg-white rounded-lg w-full h-48 shadow-inner",
                }}
              />

              {/* Clear Button */}
              <div className="mt-3 flex justify-end">
                <button
                  type="button"
                  onClick={clearSignature}
                  className="px-3 py-1.5 bg-white/20 hover:bg-white/30 text-white rounded-lg transition-colors flex items-center gap-1 text-sm"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  Apagar
                </button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-2 gap-3 mt-4">
              <button
                onClick={() => setModalAddSignature(false)}
                className="min-h-[56px] text-white rounded-lg bg-red-500 hover:bg-red-600 active:bg-red-700 transition-colors shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-[#77C5E5]"
              >
                <span className="font-medium">Cancelar</span>
              </button>
              <button
                type="button"
                className="min-h-[56px] text-white rounded-lg bg-[#92C163] hover:bg-[#84B355] active:bg-[#76A047] transition-colors shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#92C163] focus:ring-offset-2 focus:ring-offset-[#77C5E5]"
                onClick={saveResponsibleSignature}
              >
                <span className="font-medium">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
