import { Link } from "react-router-dom";
import { IoArrowUndoSharp } from "react-icons/io5";

interface PageTitleProps {
  title: string;
  backButton: boolean;
  backPath: string;
}

export function PageTitle({ title, backButton, backPath }: PageTitleProps) {
  return (
    <div className="flex flex-row items-center justify-between pt-2 pb-6">
      <h2 className="text-2xl font-bold text-[#0085BD]">{title}</h2>
      {backButton ? (
        <Link
          to={`${backPath}`}
          className="text-sm text-[#0085BD] underline underline-offset-2"
        >
          <IoArrowUndoSharp size={30} className="text-xl" />
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}
