import { useContext } from "react";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

import { PageTitle } from "../../components/PageTitle";
import { AuthContext } from "../../contexts/AuthContext";
import { TechVisitReportList } from "./TechVisitReportList/TechVisitReportList";

export function TechVisitReport() {
  const { user } = useContext(AuthContext);

  return (
    <div className="container mx-auto">
      <PageTitle
        title="Relatórios de Visita Técnica"
        backButton
        backPath="/tech-visit-reports"
      />
      <div className="flex items-start justify-between mb-6">
        <Link
          to="/tech-visit-reports/new"
          className="bg-[#0085BD] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:bg-[#006a96] transition-colors"
        >
          <FiPlus size={24} />
        </Link>
      </div>
      <TechVisitReportList />
    </div>
  );
}
