/* eslint-disable jsx-a11y/click-events-have-key-events */

"use client";

import { useNavigate, Link } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";
import {
  MdCancel,
  MdCheck,
  MdOutlinePictureAsPdf,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { PageTitle } from "../../components/PageTitle";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { Loading } from "../../components/Loading";

interface ListCostumerMonitoring {
  report_id: string;
  customer_name: string;
  services: string;
  last_report_date: string;
  alert_days_without_report: number;
  days_without_report: number;
  alert: boolean;
}

interface BigNumbersMonitoring {
  total_true: number;
  total_false: number;
}

function ReportCell({ value }: { value: string }) {
  return (
    <div className="flex justify-center">
      <Link to={`/tech-visit-reports/pdf/${value}`} target="_blank">
        <MdOutlinePictureAsPdf
          size={22}
          className="text-gray-600 hover:text-gray-800"
        />
      </Link>
    </div>
  );
}

function SituacaoCell({ value }: { value: boolean }) {
  return value === true ? (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
      NOK
    </span>
  ) : (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      OK
    </span>
  );
}

export function CostumersMonitoring() {
  const { user } = useContext(AuthContext);
  const [listCostumerMonitoring, setListCostumerMonitoring] = useState<
    ListCostumerMonitoring[]
  >([]);
  const [bigNumbersMonitoring, setBigNumbersMonitoring] =
    useState<BigNumbersMonitoring>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [tableScale, setTableScale] = useState(1);

  useEffect(() => {
    setLoading(true);
    api
      .post("/costumers/monitoring")
      .then(response => {
        setListCostumerMonitoring(response.data.costumers);
        setBigNumbersMonitoring(response.data.bigNumbers);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [navigate]);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        // Small mobile devices
        setTableScale(0.5);
      } else if (screenWidth < 768) {
        // Larger mobile devices
        setTableScale(0.75);
      } else {
        // Tablets and desktops
        setTableScale(1);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Relatório",
        accessor: "report_id",
        Cell: ReportCell,
      },
      {
        Header: "Cliente",
        accessor: "customer_name",
      },
      {
        Header: "Data Último Relatório",
        accessor: "last_report_date",
        Cell: ({ value }: { value: string }) => {
          return new Date(value).toLocaleDateString("pt-BR");
        },
      },
      {
        Header: "Dias sem Relatório",
        accessor: "days_without_report",
      },
      {
        Header: "Situação",
        accessor: "alert",
        Cell: SituacaoCell,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    { columns, data: listCostumerMonitoring, initialState: { pageIndex: 0 } },
    usePagination,
  );

  useEffect(() => {
    setPageSize(1000);
  }, [setPageSize]);

  const toggleRowExpansion = (reportId: string) => {
    if (expandedRow === reportId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(reportId);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="container mx-auto px-4">
        <PageTitle
          title="Monitoramento de Clientes"
          backButton
          backPath="/tech-visit-reports"
        />
        <div
          style={{
            transform: `scale(${tableScale})`,
            transformOrigin: "left top",
            width: `${100 / tableScale}%`,
          }}
        >
          <div className="bg-white rounded-lg mb-3 mt-3 px-4 md:px-7 py-2 shadow-md">
            <div className="mt-3">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-10 gap-3 mt-2">
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                  <div className="px-3.5 py-5 bg-white rounded-lg border border-neutral-300 justify-start items-center gap-2.5 flex">
                    <div className="mr-2 min-w-[48px] max-w-[48px]">
                      <MdCheck size={32} className="text-green-500" />
                    </div>
                    <div className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-gray-600 font-regular text-sm flex justify-start items-center gap-3">
                        Total OK
                      </span>
                      <span className="text-gray-600 font-semibold text-base">
                        {bigNumbersMonitoring?.total_false}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                  <div className="px-3.5 py-5 bg-white rounded-lg border border-neutral-300 justify-start items-center gap-2.5 flex">
                    <div className="mr-2 min-w-[48px] max-w-[48px]">
                      <MdCancel size={32} className="text-red-500" />
                    </div>
                    <div className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-gray-600 font-regular text-sm flex justify-start items-center gap-3">
                        Total NOK
                      </span>
                      <span className="text-gray-600 font-semibold text-base">
                        {bigNumbersMonitoring?.total_true}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Responsive Table Container with Scaling */}
              <div className="w-full overflow-x-auto mt-4 sm:rounded-lg">
                <table
                  {...getTableProps()}
                  className="min-w-full divide-y divide-gray-200"
                >
                  <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={headerGroup.id}
                      >
                        {headerGroup.headers.map(column => (
                          <th
                            {...column.getHeaderProps()}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider"
                            key={column.id}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="bg-white divide-y divide-gray-200"
                  >
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map(cell => (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600 text-center"
                              key={cell.column.id}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination controls */}
            {pageOptions.length > 1 && (
              <div className="flex justify-center mt-4 space-x-2">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="px-3 py-1 border rounded disabled:opacity-50"
                >
                  Anterior
                </button>
                <span className="px-3 py-1">
                  Página {pageIndex + 1} de {pageOptions.length}
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="px-3 py-1 border rounded disabled:opacity-50"
                >
                  Próxima
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
