import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { api } from "../../services/api";

import { ItemPagination } from "../../components/ItemPagination";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface Equipment {
  id: string;
  name: string;
  status: string;
}

export function Equipments() {
  const { user } = useContext(AuthContext);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [changeStatus, setChangeStatus] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/equipments")
      .then(response => {
        return setEquipments(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });
  }, [changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`equipments/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="container mx-auto">
      <PageTitle
        title="Equipamentos"
        backButton
        backPath="/tech-visit-reports"
      />
      <div className="flex items-start justify-between mb-6">
        <Link
          to="/equipments/new"
          className="bg-[#0085BD] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:bg-[#006a96] transition-colors"
        >
          <FiPlus size={24} />
        </Link>
      </div>
      <div className="space-y-2">
        {equipments.length > 0 ? (
          equipments.map(({ id, name, status }: Equipment) => (
            <ItemPagination
              key={id}
              title={name}
              id={id}
              status={status}
              hasDelete
              handleChangeStatus={() => handleChangeStatus(id, status)}
            />
          ))
        ) : (
          <div className="col-span-full">
            <div className="w-full p-5 rounded-lg bg-[#0085BD]">
              <span className="text-sm font-medium text-white">
                Nenhum equipamento encontrado 👀
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
