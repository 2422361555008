import { Link } from "react-router-dom";
import {
  MdContentPaste,
  MdOutlineStoreMallDirectory,
  MdOutlineLogout,
  MdOutlineGroups,
  MdContentCopy,
  MdFace,
  MdOutlineBuild,
  MdOutlineWorkOutline,
  MdOutlineMonitorHeart,
  MdMenu,
} from "react-icons/md";
import { useState } from "react";

interface LayoutProps {
  children: JSX.Element;
}

function MenuLink({ to, icon: Icon, children, className = "" }: any) {
  return (
    <Link
      to={to}
      className={`flex items-center w-full min-h-[56px] text-sm text-white rounded-lg bg-[#0085BD] hover:bg-[#006a96] transition-colors ${className}`}
    >
      <Icon size={26} className="mx-3" />
      <span>{children}</span>
    </Link>
  );
}

export function Layout({ children }: LayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="flex flex-col h-screen">
      <header className="h-[100px] w-full flex items-center justify-between bg-[#92C163] px-6 py-4">
        <div className="flex flex-col">
          <span className="text-lg text-white">LuviApp</span>
          {/* <span className="text-xl font-medium text-white">Usuário</span> */}
        </div>
        <button
          className="md:hidden text-white"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <MdMenu size={32} />
        </button>
      </header>
      <div className="flex-grow flex flex-col md:flex-row">
        <nav
          className={`bg-[#0085BD] w-full md:w-64 ${
            isMenuOpen ? "block" : "hidden md:block"
          }`}
        >
          <div className="p-6">
            <h2 className="text-xl font-bold text-white mb-4">Menu</h2>
            <div className="space-y-2">
              <MenuLink to="/tech-visit-reports" icon={MdContentPaste}>
                Relatório de Visita Técnica
              </MenuLink>
              <MenuLink to="/costumers" icon={MdOutlineStoreMallDirectory}>
                Clientes
              </MenuLink>
              <MenuLink to="/costumers/monitoring" icon={MdOutlineMonitorHeart}>
                Monitoramento de Clientes
              </MenuLink>
              <MenuLink to="/equipments" icon={MdOutlineBuild}>
                Equipamentos
              </MenuLink>
              <MenuLink to="/services" icon={MdOutlineWorkOutline}>
                Serviços
              </MenuLink>
              <MenuLink to="/sectors" icon={MdContentCopy}>
                Setores
              </MenuLink>
              <MenuLink to="/responsibles" icon={MdFace}>
                Responsáveis
              </MenuLink>
              <MenuLink to="/users" icon={MdOutlineGroups}>
                Usuários
              </MenuLink>
              <MenuLink
                to="/signin"
                icon={MdOutlineLogout}
                className="bg-danger-500 hover:bg-red-800"
              >
                Logout
              </MenuLink>
            </div>
          </div>
        </nav>
        <main className="flex-grow p-6 bg-[#E5F3F7]">{children}</main>
      </div>
    </div>
  );
}
