import { Routes as Router, Route } from "react-router-dom";
import { PrivateRoute } from "./Route";

import { SignIn } from "../pages/SignIn";
import { ResetPassword } from "../pages/ResetPassword";
import { Dashboard } from "../pages/Dashboard";
import { Costumers } from "../pages/Costumers";
import { Equipments } from "../pages/Equipments";
import { Services } from "../pages/Services";
import { Sectors } from "../pages/Sectors";
import { Users } from "../pages/Users";
import { Responsibles } from "../pages/Responsibles";

import { EquipmentEdit } from "../pages/Equipments/EquipmentEdit/EquipmentEdit";
import { SectorEdit } from "../pages/Sectors/SectorEdit/SectorEdit";
import { CostumerEdit } from "../pages/Costumers/CostumerEdit/CostumerEdit";
import { UserEdit } from "../pages/Users/UserEdit/UserEdit";
import { ServiceEdit } from "../pages/Services/ServiceEdit/ServiceEdit";
import { ResponsibleEdit } from "../pages/Responsibles/ResponsibleEdit/ResponsibleEdit";
import { TechVisitReport } from "../pages/TechVisitReport";
import { TechVisitReportCreate } from "../pages/TechVisitReport/TechVisitReportForms/TechVisitReportCreate";
import { TestPage } from "../pages/TestPage";
import { TechVisitReportList } from "../pages/TechVisitReport/TechVisitReportList/TechVisitReportList";
import { TechVisitReportConfirm } from "../pages/TechVisitReport/TechVisitReportForms/TechVisitReportConfirm";
import { TechVisitReportEdit } from "../pages/TechVisitReport/TechVisitReportForms/TechVisitReportEdit";
import { TechVisitReportPDF } from "../pages/TechVisitReport/TechVisitReportPDF/TechVisitReportPDF";
import { CostumersMonitoring } from "../pages/CostumersMonitoring";
import { Layout } from "../components/Layout";

export function Routes(): JSX.Element {
  return (
    <Router>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="/tests" element={<TestPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />

      <Route path="/costumers">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <Costumers />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <CostumerEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <CostumerEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="monitoring"
          element={
            <PrivateRoute>
              <Layout>
                <CostumersMonitoring />
              </Layout>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/equipments">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <Equipments />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <EquipmentEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <EquipmentEdit />
              </Layout>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/services">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <Services />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <ServiceEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <ServiceEdit />
              </Layout>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/sectors">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <Sectors />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <SectorEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <SectorEdit />
              </Layout>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/responsibles">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <Responsibles />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <ResponsibleEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <ResponsibleEdit />
              </Layout>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/users">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <Users />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <UserEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <UserEdit />
              </Layout>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/tech-visit-reports">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Layout>
                <TechVisitReport />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Layout>
                <TechVisitReportCreate />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="new/:id"
          element={
            <PrivateRoute>
              <Layout>
                <TechVisitReportCreate />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="confirm/:id"
          element={
            <PrivateRoute>
              <Layout>
                <TechVisitReportConfirm />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <Layout>
                <TechVisitReportEdit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route path="pdf/:id" element={<TechVisitReportPDF />} />
      </Route>
    </Router>
  );
}
