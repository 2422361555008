/* eslint-disable import/no-duplicates */
import {
  MdOutlineMail,
  MdOutlinePictureAsPdf,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FiCalendar } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import { useState } from "react";
import moment from "moment";
import { ModalUpdateStatusTechVisitReport } from "./ModalUpdateStatusTechVisitReport";
import { ModalSendMail } from "../pages/TechVisitReport/components/ModalSendMail";

interface Service {
  name: string;
}

interface ItemPaginationReportProps {
  id: string;
  title: string;
  begin: string;
  pending: boolean;
  sent: boolean;
  status: string;
  services: Service[];
  costumer_id: string;
  code: string;
  setPagination(): void;
}

export function ItemPaginationReport({
  id,
  title,
  begin,
  pending,
  sent,
  status,
  services,
  costumer_id,
  code,
  setPagination,
}: ItemPaginationReportProps) {
  const date = moment(begin).format("DD/MM/YYYY H:mm");
  const dangerCSS = "ml-1 p-1 text-white text-xs bg-danger-500 rounded-lg";
  const sucessCSS = "ml-1 p-1 text-white text-xs bg-[#92C163] rounded-lg";

  const [modal, showModal] = useState(false);
  const [modalSendMail, showModalSendMail] = useState(false);

  return (
    <>
      <ModalUpdateStatusTechVisitReport
        showModal={modal}
        onHide={() => showModal(false)}
        statusUpdated={() => {
          setPagination();
        }}
        id={id}
        status={status === "A" ? "I" : "A"}
      />
      <ModalSendMail
        showModalSendMail={modalSendMail}
        onHide={() => showModalSendMail(false)}
        costumer_id={costumer_id}
        report_id={id}
      />
      <div className="flex items-center justify-between h-full mt-2 w-full rounded-lg bg-white p-4">
        <div className="flex flex-col gap-2">
          <h1 className="text-[#0085BD] font-medium">
            {code} - {title}
          </h1>
          <div className="flex">
            <FiCalendar className="text-[#0085BD]" />
            <span className="ml-1 text-[#0085BD] text-xs">{date}</span>
          </div>
          <div className="flex">
            {services.map(({ name }) => (
              <span className="ml-1 text-white text-xs bg-[#0085BD] p-1 rounded-md">
                {name}
              </span>
            ))}
          </div>
          <div className="flex items-center justify-items-start gap-1">
            <Link
              key={id}
              to={{
                pathname: `edit/${id}`,
              }}
              className="w-8 h-8 flex items-center justify-center rounded-xl bg-[#0085BD]"
            >
              <MdOutlineRemoveRedEye size={16} className="text-white" />
            </Link>
            <Link
              key={id}
              to={{
                pathname: `pdf/${id}`,
              }}
              className="w-8 h-8 flex items-center justify-center rounded-xl bg-[#0085BD]"
            >
              <MdOutlinePictureAsPdf size={16} className="text-white" />
            </Link>
            <button
              type="button"
              onClick={() => showModalSendMail(true)}
              className="w-8 h-8 flex items-center justify-center rounded-xl bg-[#0085BD]"
            >
              <MdOutlineMail size={16} className="text-white" />
            </button>
            <span className={sent === true ? sucessCSS : dangerCSS}>
              {sent === true ? "Enviado" : "Não enviado"}
            </span>
            <span className={pending === true ? dangerCSS : sucessCSS}>
              {pending === true ? "Pendente" : "Concluído"}
            </span>
          </div>
        </div>
        <div>
          {status && status === "A" ? (
            <button
              type="button"
              onClick={() => showModal(true)}
              className="w-8 h-8 flex items-center justify-center rounded-xl bg-green-600"
            >
              <AiOutlineCheck size={16} className="text-white" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => showModal(true)}
              className="w-8 h-8 flex items-center justify-center rounded-xl bg-red-500"
            >
              <BsTrash size={16} className="text-white" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
