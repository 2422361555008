/* eslint-disable import/no-extraneous-dependencies */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";

import { FormButton } from "../../../components/FormButton";
import { Input } from "../../../components/Input";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { PageTitle } from "../../../components/PageTitle";

interface User {
  name: string;
  occupation: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export function UserEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [userProps, setUserProps] = useState<User>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      api.get(`users/show/${id}`).then(response => {
        return setUserProps(response.data);
      });
    }
  }, [id]);

  function handleSubmit(data: User) {
    if (id) {
      api
        .put(`/users/update/${id}`, data)
        .then(() => {
          enqueueSnackbar("Usuário editado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    } else {
      api
        .post("/users", data)
        .then(() => {
          enqueueSnackbar("Usuário cadastrado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    }
    return navigate("/users");
  }

  const schema = Yup.object().shape({
    name: Yup.string().required("Campo nome é obrigatório"),
    occupation: Yup.string().required("Campo ocupação é obrigatório"),
    email: Yup.string()
      .email("E-mail inválido")
      .required("Campo e-mail é obrigatório"),
    // password: Yup.string().required("Campo senha é obrigatório"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Confirmação de senha está incorreta",
    ),
  });

  const initialValues = {
    name: userProps ? userProps.name : "",
    occupation: userProps ? userProps.occupation : "",
    email: userProps ? userProps.email : "",
    password: "",
    password_confirmation: "",
  };

  return (
    <div className="container mx-auto">
      <PageTitle
        title={id ? "Editar Usuário" : "Novo Usuário"}
        backButton
        backPath="/users"
      />
      <div className="w-full">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ errors }) => (
              <Form className="space-y-4">
                <div className="space-y-2">
                  <span className="text-sm text-[#0085BD] font-medium">
                    Nome
                  </span>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    error={errors.name}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  <span className="text-sm text-[#0085BD] font-medium">
                    Ocupação
                  </span>
                  <Input
                    type="text"
                    name="occupation"
                    placeholder="Ocupação"
                    error={errors.occupation}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  <span className="text-sm text-[#0085BD] font-medium">
                    E-mail
                  </span>
                  <Input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    error={errors.email}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  <span className="text-sm text-[#0085BD] font-medium">
                    Senha
                  </span>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Senha"
                    error={errors.password}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                  <span className="text-sm text-[#0085BD] font-medium">
                    Confirme a senha
                  </span>
                  <Input
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirme a senha"
                    error={errors.password_confirmation}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white border border-gray-200 focus:border-[#0085BD] focus:ring-1 focus:ring-[#0085BD]"
                  />
                </div>
                <FormButton
                  title={id ? "Editar Usuário" : "Cadastrar Usuário"}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
